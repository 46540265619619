<template>
  <v-container id="user_mgr-cashflow" fluid tag="section">
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('user_mgr.cashflow')"
      class="px-5 py-3"
    >
      <v-row class="mt-2">
        <v-col cols="auto">
          <v-text-field
            v-model="user_balance"
            :label="$t('user_mgr.balance')"
            readonly
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col class="text-right">
          <dialog-message
            :dialog_message="dialog_message"
            :message="message"
          ></dialog-message>
          <v-dialog v-model="transaction.dialog" max-width="70%">
            <v-card>
              <v-card-title>
                <span class="headline">{{ $t("user_mgr.add_cashflow") }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row dense>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="transaction.amount"
                        :label="$t('user_mgr.amount')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="8">
                      <v-text-field
                        v-model="transaction.description"
                        :label="$t('user_mgr.description')"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="resetTransaction()">
                  {{ $t("common.cancel") }}
                </v-btn>
                <v-btn color="blue darken-1" text @click="addTransaction()">
                  {{ $t("user_mgr.add") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn
            v-if="role == UserRole.Admin"
            color="aqua"
            class="mx-2"
            @click="back()"
            >{{ $t("common.back") }}</v-btn
          >
          <v-btn
            color="primary"
            dark
            class="mx-2"
            v-if="role == UserRole.Admin"
            @click="createNewTransaction()"
          >
            <v-icon left>mdi-pencil-plus</v-icon>
            {{ $t("user_mgr.add_cashflow") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        multi-sort
      >
        <template v-slot:item.timestamp="{ item }">
          {{ TransDate(item.timestamp) }}
        </template>

        <template v-slot:item.transaction_type="{ item }">{{
          getTranscriptionType(item.transaction_type)
        }}</template>
        <!-- 新增 description 的自定義樣式 -->
        <template v-slot:item.description="{ item }">
          <div style="width: 350px">{{ item.description }}</div>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import { UserRole, UserRoleItems, CreateUserRoleItems } from "@/definition";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
export default {
  data: (vm) => ({
    UserRole: UserRole,
    UserRoleItems: UserRoleItems,
    CreateUserRoleItems: CreateUserRoleItems,
    dialog_message: false,
    valid: true,
    meeting: { title: null },
    items: [],
    totalItems: 0,
    user_balance: 0,
    loading: false,
    dialog_form: false,
    dialog_password: false,
    upload_form: false,
    uploadFile: null,
    options: {},
    newUser: true,
    userId: null,
    showPassword: false,
    message: {
      title: "",
      text: "",
    },
    footerProps: {
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: "mdi-chevron-double-left",
      lastIcon: "mdi-chevron-double-right",
      prevIcon: "mdi-chevron-left",
      nextIcon: "mdi-chevron-right",
      itemsPerPageOptions: [5, 10, 20],
    },
    headers: [
      {
        sortable: false,
        text: vm.$i18n.t("user_mgr.amount"),
        value: "amount",
        width: "20%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("user_mgr.transaction_type"),
        value: "transaction_type",
        width: "20%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("user_mgr.description"),
        value: "description",
        width: "40%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("user_mgr.username"),
        value: "user_name",
        width: "5%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("user_mgr.timestamp"),
        value: "timestamp",
        width: "20%",
      },
      {
        sortable: false,
        // text: vm.$i18n.t("common.actions"),
        text: "",
        value: "actions",
      },
    ],
    userId: null,
    company_id:null,
    transaction: {
      dialog: false,
      amount: 0,
      description: "",
    },
  }),
  created() {
    // 在组件创建时获取路由参数
    this.getUserIdFromRoute();
  },
  components: {
    datetime: Datetime,
  },
  computed: {
    ...mapState(["token", "role"]),
  },
  methods: {
    ...mapMutations(["setTitle"]),
    close() {
      this.dialog_form = false;
      this.loading = false;
      this.transaction.dialog = false;
    },
    getApi() {
      this.loading = true;
      const currentObj = this;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/plan/transactions/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
        params: {
          page: page,
          page_size: itemsPerPage,
          user_id: this.userId,
          company_id:this.company_id,
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          currentObj.loading = false;
          currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("get-api-fail");
          currentObj.message.text = currentObj.$t(error.response.data.detail);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    setResult(data) {
      let info = JSON.parse(JSON.stringify(data));
      this.totalItems = info.count;
      this.items = data.results;
      this.user_balance = info.user_balance;
    },

    save() {},
    getUserIdFromRoute() {
      // 从路由参数中获取 user_id
      this.userId = this.$route.query.user_id;
      if (this.userId) {
        this.getApi();
        return;
      }
      this.company_id = this.$route.query.company_id;
      if (this.company_id) {
        this.getApi();
      }
    },
    getTranscriptionType(type) {
      return this.$i18n.t("user_mgr.transaction_type_" + type);
    },
    back() {
      if (this.role == UserRole.Admin) {
        if(this.company_id != null){
          this.$router.push({ name: "CompanyMgr" });
        }else{
          this.$router.push({ name: "UserMgr" });
        }
      }
    },
    createNewTransaction() {
      this.transaction.dialog = true;
    },
    resetTransaction() {
      this.transaction.amount = 0;
      this.transaction.description = "";
      this.transaction.dialog = false;
    },
    addTransaction() {
      if (this.transaction.amount <= 0) {
        alert("請填入金額");
        return;
      }
      this.loading = true;
      const currentObj = this;
      const url = process.env.VUE_APP_SERVER_URL + "/plan/balance/deposit/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token,
        },
      };
      console.log("getApi", url, config);
      let data = {
        user: this.userId,
        company_id:this.company_id,
        balance: this.transaction.amount,
        description: this.transaction.description,
      };

      this.axios
        .post(url, data, config)
        .then(function (response) {
          console.log(response);
          currentObj.loading = false;
          // currentObj.setResult(response.data);
          currentObj.showDepositSuccess();
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("get-api-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    showDepositSuccess() {
      this.transaction.dialog = false;
      this.getApi();
      this.message.title = this.$t("user_mgr.transaction_type_DEPOSIT");
      this.message.text = this.$t("user_mgr.deposit_success_text");
      this.dialog_message = !this.dialog_message;
      this.resetTransaction();
    },
  },
  watch: {
    options: {
      handler() {
        this.getApi();
      },
    },
    $route(to, from) {
      this.getUserIdFromRoute();
    },
  },
  mounted() {
    this.setTitle(this.$i18n.t("user_mgr.cashflow"));
  },
};
</script>

<style lang="sass" scope>
.v-form
  width: 100%
  .v-btn__content
    a
      color: #FFFFFF
      text-decoration: none
</style>
